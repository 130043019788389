// =============================================================================
// Links
// =============================================================================

%animate {
    -webkit-transition: all 0.2s ease;
    -moz-transition   : all 0.2s ease;
    -o-transition     : all 0.2s ease;
    transition        : all 0.2s ease;

    &:after,
    &:before {
        -webkit-transition: all 0.2s ease;
        -moz-transition   : all 0.2s ease;
        -o-transition     : all 0.2s ease;
        transition        : all 0.2s ease;
    }
}

a {
    @extend %animate;

    &:active,
    &:link,
    &:visited {
        color        : $color-body;
        border-bottom: 1px solid;
    }

    &:hover {
        color: $color-highlight;
    }

    &.more {
        font-family   : $header-font;
        text-transform: uppercase;

        &:active,
        &:link,
        &:visited {
            color        : $color-highlight;
            border-bottom: none;
        }

        [class*=" icon-"],
        [class^="icon-"] {
            &:before {
                vertical-align: middle;
            }
        }

        &:hover {
            color: $color-body;

            [class*=" icon-"],
            [class^="icon-"] {
                &:before {
                    margin-left: 0.5em;
                }
            }
        }
    }
}
