// =============================================================================
// Type
// =============================================================================

a,
h1,
h2,
h3,
h4,
h5,
li,
p {
    margin: 0;
}

h1 {
    font-family  : $header-font;
    font-size    : 2em;
    font-weight  : normal;
    font-style   : normal;
    margin-bottom: 1rem;

    .logout {
        text-transform: none;

        &:active,
        &:link,
        &:visited {
            color: $color-body;
        }

        &:hover {
            color: $color-background;
        }
    }
}

h2 {
    font-family  : $header-font;
    font-size    : 1.500em;
    font-weight  : normal;
    font-style   : normal;
    margin-bottom: 1rem;
}

h3 {
    font-family  : $header-font;
    font-size    : 1.375em;
    font-weight  : normal;
    font-style   : normal;
    margin-bottom: 1rem;
}

h2,
h3,
h4 {
    &.dash {
        position     : relative;
        margin-bottom: 3rem;

        &:before {
            content   : "";
            display   : block;
            width     : 32px;
            height    : 4px;
            background: $color-primary;
            position  : absolute;
            left      : 0;
            bottom    : -18px;
        }
    }
}

h4 {
    font-family  : $header-font;
    font-size    : 1.125em;
    font-weight  : normal;
    font-style   : normal;
    margin-bottom: 1rem;
}

li,
p {
    font-family: $body-font;
    font-size  : 1em;
    font-weight: 400;
    font-style : normal;
}

p {
    margin-bottom: 1em;

    &.error {
        color: red;
    }
}

b,
strong {
    font-family: $header-font;
    font-weight: normal;
    font-style : normal;
}
@font-face {
    font-family: "New Transport Bold";
    src        : url("../fonts/NewTransport-Bold.eot");
    src        : url("../fonts/NewTransport-Bold.eot?#iefix") format('embedded-opentype'), url("../fonts/NewTransport-Bold.woff2") format('woff2'), url("../fonts/NewTransport-Bold.woff") format('woff'), url("../fonts/NewTransport-Bold.ttf") format('truetype'), url("../fonts/NewTransport-Bold.svg#NewTransport-Bold") format('svg');
    font-weight: normal;
    font-style : normal;
}
@font-face {
    font-family: "New Transport";
    src        : url("../fonts/NewTransport-Regular.eot");
    src        : url("../fonts/NewTransport-Regular.eot?#iefix") format('embedded-opentype'), url("../fonts/NewTransport-Regular.woff2") format('woff2'), url("../fonts/NewTransport-Regular.woff") format('woff'), url("../fonts/NewTransport-Regular.ttf") format('truetype'), url("../fonts/NewTransport-Regular.svg#NewTransport-Regular") format('svg');
    font-weight: normal;
    font-style : normal;
}
@font-face {
    font-family: "New Transport Thin";
    src        : url("../fonts/NewTransport-Thin.eot");
    src        : url("../fonts/NewTransport-Thin.eot?#iefix") format('embedded-opentype'), url("../fonts/NewTransport-Thin.woff2") format('woff2'), url("../fonts/NewTransport-Thin.woff") format('woff'), url("../fonts/NewTransport-Thin.ttf") format('truetype'), url("../fonts/NewTransport-Thin.svg#NewTransport-Thin") format('svg');
    font-weight: normal;
    font-style : normal;
}
@font-face {
    font-family: "rsc";
    src        : url("../fonts/rsc.eot?5blhvn");
    src        : url("../fonts/rsc.eot?5blhvn#iefix") format('embedded-opentype'), url("../fonts/rsc.ttf?5blhvn") format('truetype'), url("../fonts/rsc.woff?5blhvn") format('woff'), url("../fonts/rsc.svg?5blhvn#rsc") format('svg');
    font-weight: normal;
    font-style : normal;
}

[class*=" icon-"],
[class^="icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family            : "rsc" !important;
    speak                  : none;
    font-style             : normal;
    font-weight            : normal;
    font-variant           : normal;
    text-transform         : none;
    line-height            : 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing : antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-menu:before {
    content: "\e900";
}

.icon-arrow_upward:before {
    content: "\e901";
}

.icon-arrow_forward:before {
    content: "\e902";
}

.icon-arrow_downward:before {
    content: "\e903";
}

.icon-arrow_back:before {
    content: "\e904";
}

.icon-twitter:before {
    content: "\e906";
}

.icon-facebook-square:before {
    content: "\e907";
}

.icon-map-pin:before {
    content: "\e908";
}

.icon-file:before {
    content: "\e905";
}
