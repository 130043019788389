// =============================================================================
// Forms
// =============================================================================

label {
    font-family  : $body-font;
    font-weight  : normal;
    margin-bottom: 0;
}

input[type=email],
input[type=input],
input[type=password],
input[type=text],
textarea {
    font-family  : $body-font;
    border       : 1px solid darken($color-background, 20%);
    box-shadow   : none;
    border-radius: 0;
    margin-bottom: 1em;
    font-size    : 1em;
    padding      : 1rem;

    &:focus {
        outline     : 0;
        box-shadow  : none;
        border-color: $color-highlight;
    }
}

textarea.portal-feedback {
    height: 300px;
}

input[type=submit] {
    font-family   : $header-font;
    text-transform: uppercase;
    border-radius : 0;
    background    : $color-highlight;
    padding       : 0.7em 1em 0.5em;

    &:hover {
        background: $color-body;
    }
}
