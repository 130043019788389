// =============================================================================
// Footer
// =============================================================================

.site-footer {
    width     : $full;
    max-width : $max-width;
    margin    : 0 auto;
    background: darken($color-body, 9%);
    color     : $color-highlight;
    @include respond-to(xlarge) {
        position: fixed;
        bottom  : 0;
        z-index : 0;
    }

    .row {
        @include clearfix;
        padding      : $global-padding $global-padding 0;
        width        : $full;
        border-bottom: 1px solid darken($color-secondary, 10%);
        font-size    : 0;
        background   : transparent;
        @include respond-to(xlarge) {
            padding: $global-padding;
        }

        .footer-block {
            display       : inline-block;
            vertical-align: top;
            width         : $full;
            margin-bottom : 2rem;
            @include respond-to(large) {
                width: 50%;
            }
            @include respond-to(xlarge) {
                width        : 25%;
                margin-bottom: 0;
            }

            .full-nav {
                &.disc {
                    list-style-type: square !important;
                    margin-left    : 1.5rem;

                    li {
                        text-indent: -5px;
                    }
                }

                li {
                    i {
                        display     : inline-block;
                        text-align  : center;
                        width       : 20px;
                        margin-right: 5px;
                    }
                }
            }

            a,
            li,
            p {
                font-size: 1rem;
            }

            > a {
                font-family: $header-font;
            }

            a {
                &:active,
                &:link,
                &:visited {
                    color        : $color-highlight;
                    border-bottom: none;
                }

                &:hover {
                    color        : $color-highlight;
                    border-bottom: 1px solid;
                }
            }

            &.empty {
                display: none;
                @include respond-to(xlarge) {
                    display: inline-block;
                }
            }

            .address {
                width: 240px;
            }

            .footer-logo {
                float: left;

                img {
                    width  : 100px;
                    opacity: 0.5;
                }

                a {
                    border: none;

                    &:before {
                        display: none;
                    }
                }

                // a {
                //     color      : lighten($color-secondary, 20%);
                //     font-size  : 2.5rem;
                //     font-family: $header-font;
                //     border     : none;
                //
                //     &:before {
                //         display: none;
                //     }
                // }
                //
                // span {
                //     border-bottom: 4px solid lighten($color-secondary, 20%);
                //     margin       : 0 2px 0 0;
                // }
            }
        }

        .copy {
            width        : $full;
            margin-bottom: 2rem;
            @include respond-to(xlarge) {
                width : 25%;
                margin: 0;
            }
            font-size    : 1rem;
            font-family  : $header-font;
            float        : right;
        }
    }
}
