// =============================================================================
// About
// =============================================================================

.latest-news {
    height: auto!important;

    .latest {
        @include clearfix;
        height: 280px;
        width : $full;
        float : left;
        @include respond-to(xxlarge) {
            width: 50%;
        }

        &.img {
            @include bg-size;
            background-position: center;
            height             : 240px;
            @include respond-to(large) {
                height: 280px;
            }
        }

        &.text {
            position: relative;
            @include respond-to(xxlarge) {
                &:after {
                    right         : 100%;
                    top           : 50%;
                    border        : solid transparent;
                    content       : " ";
                    height        : 0;
                    width         : 0;
                    position      : absolute;
                    pointer-events: none;
                    border-color  : rgba(136, 183, 213, 0);
                    border-width  : 30px;
                    margin-top    : -30px;
                }
            }
        }
    }

    &:nth-child(1n) {
        .latest {
            &:last-child {
                background: darken($color-background, 16%);

                &:after {
                    border-right-color: darken($color-background, 16%);
                }
            }
        }
    }

    &:nth-child(2n) {
        .latest {
            &:last-child {
                background: darken($color-background, 12%);

                &:after {
                    border-right-color: darken($color-background, 12%);
                }
            }
        }
    }

    &:nth-child(3n) {
        .latest {
            &:last-child {
                background: darken($color-background, 8%);

                &:after {
                    border-right-color: darken($color-background, 8%);
                }
            }
        }
    }

    &:nth-child(4n) {
        .latest {
            &:last-child {
                background: darken($color-background, 4%);

                &:after {
                    border-right-color: darken($color-background, 4%);
                }
            }
        }
    }

    h2 {
        color: $color-highlight;
    }
}

&.certificates-carousel {
    width : $full;
    height: 320px;

    .grid-panel {
        &.certificates-carousel-cell {
            height : 320px;
            padding: $global-padding $global-padding $global-padding*2.5;
            color  : $color-body-secondary;

            &:nth-child(1n) {
                background: lighten($color-primary, 2%);
            }

            &:nth-child(2n) {
                background: lighten($color-primary, 4%);
            }

            &:nth-child(3n) {
                background: lighten($color-primary, 6%);
            }

            &:nth-child(4n) {
                background: lighten($color-primary, 8%);
            }

            .content-block {
                img {
                    height       : 60px;
                    width        : auto;
                    margin-bottom: 1rem;
                    display      : none;
                }

                a {
                    &:hover {
                        color: $color-body-secondary;
                    }
                }
            }
        }
    }
}

.staff-carousel {
    //height: 500px;
    //min-height: 500px;
}
