// =============================================================================
// Services
// =============================================================================

.sub-services-feature {
    height: auto!important;
    width : $full!important;

    .sub-service {
        @include clearfix;
        height: 340px;
        width : $full;
        float : left;
        @include respond-to(xxlarge) {
            width: 50%;
        }

        &.img {
            @include bg-size;
            background-position: center;
            height             : 280px;
            @include respond-to(large) {
                height: 340px;
            }
        }

        &.text {
            position: relative;
            height  : auto;
            @include respond-to(large) {
                height: 340px;
            }
            @include respond-to(xxlarge) {
                &:after {
                    right         : 100%;
                    top           : 50%;
                    border        : solid transparent;
                    content       : " ";
                    height        : 0;
                    width         : 0;
                    position      : absolute;
                    pointer-events: none;
                    border-color  : rgba(136, 183, 213, 0);
                    border-width  : 30px;
                    margin-top    : -30px;
                }
            }
        }

        .content-block {
            position: inherit!important;
            height  : auto;
            @include respond-to(large) {
                position: absolute!important;
            }
        }
    }

    &:nth-child(1n) {
        .sub-service {
            &:last-child {
                background: darken($color-background, 16%);

                &:after {
                    border-right-color: darken($color-background, 16%);
                }
            }
        }
    }

    &:nth-child(2n) {
        .sub-service {
            &:last-child {
                background: darken($color-background, 12%);

                &:after {
                    border-right-color: darken($color-background, 12%);
                }
            }
        }
    }

    &:nth-child(3n) {
        .sub-service {
            &:last-child {
                background: darken($color-background, 8%);

                &:after {
                    border-right-color: darken($color-background, 8%);
                }
            }
        }
    }

    &:nth-child(4n) {
        .sub-service {
            &:last-child {
                background: darken($color-background, 4%);

                &:after {
                    border-right-color: darken($color-background, 4%);
                }
            }
        }
    }

    h2 {
        color: $color-highlight;
    }
}
