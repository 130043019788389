// =============================================================================
// Header
// =============================================================================

.site-header {
    width        : $full;
    background   : $color-body-secondary;
    position     : relative;
    z-index      : 3;
    border-bottom: 1px solid darken($color-background, 4%);
}
