
// =============================================================================
// Variables
// =============================================================================

// width
$full                : 100%;

// descriptive colours
$white               : #fff;
$black               : #000;
$light-grey          : #f3f3f3;

// //https://coolors.co/cad3c6-fffff2-3e4f59-00668c-002e56
// $prussian-blue            : #002E56;
// $sea-blue                 : #00668C;
// $slate                    : #3E4F59;
// $ivory                    : #F5F2E9;
// $pastel-gray              : #CAD3C6;
//
// // secondary
// $rich-navy                : #000D18;
// $texas-green              : #008C3C;

//https://coolors.co/cad3c6-fffff2-3e4f59-00668c-002e56
$dark-navy           : #233342;
$light-blue          : #93b4bd;

$slate               : #3E4F59;
$pastel-gray         : #CAD3C6;

// secondary
$green               : #73ff8d;
$cyan-blue           : #a6f5ff;
$yellow              : #ffc440;

// site colors
$color-primary       : $dark-navy;
$color-secondary     : $slate;
$color-tertiary      : $pastel-gray;

$color-body          : $dark-navy;
$color-body-secondary: $white;
//$color-background         : lighten($light-blue, 25%);
$color-background    : $light-grey;
$color-highlight     : $light-blue;

// base font
$base-font-size      : 16px;

// type
$header-font         : "New Transport Bold";
$body-font           : "New Transport";
$body-font-thin      : "New Transport Thin";

// breakpoints
$small               : em(320px);
$medium              : em(600px);
$large               : em(800px);
$xlarge              : em(1000px);
$xxlarge             : em(1200px);

// max width
$max-width           : em(1400px);

// global padding
$global-padding      : 2rem;
