// =============================================================================
// Contact
// =============================================================================

#map {
    position: absolute;
    width   : $full;
    height  : $full;
    top     : 0;
    z-index : -1;
}

.address {
    margin-bottom: $global-padding;
}
