// ==========================================================================
// Helper Classes
// ==========================================================================

%all-caps {
  font-weight: 600;
  text-transform: uppercase;
}

%clearfix {
  *zoom:1;
  &:before, &:after { content: " "; display: table; }
  &:after { clear: both; }
}

%ir {
  background-color: transparent;
  border: 0;
  overflow: hidden;/* IE 6/7 fallback */
  *text-indent: -9999px;
  &:before {
    content: "";
    display: block;
    width: 0;
    height: 150%;
  }
}

%no-bullets {
  list-style: none;
  margin-top: 0;
  margin-left: 0;
  padding-left: 0;
}

%absolute-center {
	position:absolute;
	top:50%;
	left:50%;
	-webkit-transform:translate(-50%,-50%);
	-moz-transform:translate(-50%,-50%);
	-ms-transform:translate(-50%,-50%);
	-o-transform:translate(-50%,-50%);
}

%hidden {
  display: none !important;
  visibility: hidden;
}

%visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  &.focusable:active,
  &.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}

%invisible {
  visibility: hidden;
}
