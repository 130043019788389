// =============================================================================
// Lists
// =============================================================================

.fade-in {
    opacity: 0;
}

.services-feature-list {
    @include clearlist;
    // padding: $global-padding 0;
    // @include respond-to(large) {
    //     padding: $global-padding*2 0;
    // }

    &.brdr-top {
        padding-top   : $global-padding*2;
        margin-top    : $global-padding*2;
        border-top    : 1px solid darken($color-background, 20%);
        padding-bottom: 0;
    }

    > li {
        @include clearfix;

        &:not(:last-child) {
            border-bottom : 1px solid darken($color-background, 20%);
            padding-bottom: $global-padding;
            margin-bottom : $global-padding;
            @include respond-to(large) {
                padding-bottom: $global-padding*2;
                margin-bottom : $global-padding*2;
            }
        }

        &:only-child {
            padding-bottom: 0;
            margin-bottom : 0;
        }

        .col {
            float        : left;
            width        : $full;
            margin-bottom: 0 !important;
            @include respond-to(large) {
                &.x1_2 {
                    width: 50%;
                    //padding-left: $global-padding;
                }
            }

            .img-wrap {
                width: $full;
                @include respond-to(large) {
                    height       : 300px;
                    overflow     : hidden;
                    padding-right: $global-padding;
                }

                img {
                    width        : $full;
                    margin-bottom: $global-padding;
                }
            }
        }
    }
}

.services-list {
    @include clearlist;

    li {
        font-size  : 1.125em;
        line-height: 1.500em;

        a {
            &.current {
                color      : $color-body;
                font-family: $header-font;
            }
        }

    }
}

.careers-requirements {
    margin-left  : $global-padding;
    list-style   : disc;
    margin-bottom: 1rem;
}

.article-list {
    @include clearlist;
    margin-left: 0 !important;
    list-style : none!important;

    li {
        &:not(:last-child) {
            border-bottom : 1px solid darken($color-background, 20%);
            padding-bottom: $global-padding;
            margin-bottom : $global-padding;
            @include respond-to(large) {
                padding-bottom: $global-padding*2;
                margin-bottom : $global-padding*2;
            }
        }
    }

    h2 {
        margin-bottom: 0;
    }

    h4 {
        &.date {
            font-family: $body-font;
        }
    }
}

.breadcrumbs {
    @include clearlist;
    line-height: 0;

    li {
        display: inline-block;

        a {
            &:active,
            &:link,
            &:visited {
                color : $color-highlight;
                border: none;
            }

            &:hover {
                color: $color-background;
            }
        }
    }
}
