// =============================================================================
// Buttons
// =============================================================================

.btn {
    display       : inline-block;
    background    : transparent;
    padding       : 1em;
    text-align    : center;
    font-size     : 0.750em;
    font-family   : $header-font;
    text-transform: uppercase;
    border-bottom : none;

    &:active,
    &:link,
    &:visited {
        background: $color-primary;
        color     : $color-background;
    }

    &:hover {
        background: $color-highlight;
        color     : $color-body-secondary;
    }

    &.disabled {
        pointer-events: none;
        cursor        : default;
        opacity       : 0.5;
    }

    &:focus {
        outline: 0;
    }
}
