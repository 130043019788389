// =============================================================================
// Nav
// =============================================================================

.site-nav {
    @include clearfix;
    //height : 80px;
    padding: 0 1rem;

    .site-logo {
        float   : left;
        position: absolute;
        top     : 1.5rem;
        left    : 1rem;

        img {
            height: 40px;
        }
        @include respond-to(xxlarge) {
            top : 1rem;
            left: 2rem;

            img {
                height: 50px;
            }
        }

        a {
            padding: 0;

            &:before {
                display: none;
            }
        }

        // a {
        //     color      : $color-body!important;
        //     font-size  : 2.5rem;
        //     font-family: $header-font;
        //     padding    : 0;
        //     @include respond-to(xxlarge) {
        //         //font-size  : 7rem;
        //         //line-height: 7rem;
        //     }
        //
        //     &:before {
        //         display: none;
        //     }
        // }

        // span {
        //     position: relative;
        //     margin  : 0 4px 0 0;
        //
        //     &:before {
        //         content   : "";
        //         display   : block;
        //         width     : 100%;
        //         height    : 4px;
        //         background: $color-body;
        //         position  : absolute;
        //         left      : 0;
        //         bottom    : 0;
        //         @include respond-to(xxlarge) {
        //             //bottom: 10px;
        //             //height: 8px;
        //         }
        //     }
        // }

    }

    a {
        display        : block;
        text-decoration: none;
        margin         : 0;
        position       : relative;
        font-family    : $body-font;
        padding        : 1rem;
        border         : 0;
        @include respond-to(xlarge) {
            padding: 1.8rem 1rem;

            &:before {
                // background: $color-body;
                // content   : "";
                // height    : 1px;
                // left      : 1rem;
                // opacity   : 0;
                // position  : absolute;
                // right     : 1rem;
                // bottom    : 1rem;
                background: lighten($color-highlight, 15%);
                content   : "";
                height    : 2.4rem;
                left      : 0;
                opacity   : 0;
                position  : absolute;
                right     : 0;
                z-index   : -1;
                bottom    : 1rem;
            }
        }

        &:active,
        &:link,
        &:visited {
            color: rgba($color-body, 0.5);
        }

        &:hover {
            color: $color-body;

            &:before {
                opacity: 1;
                bottom : 1.4rem;
                //width  : calc(100% - 2rem);
            }
        }
    }

    .nav-list {
        @include clearlist;
        float         : right;
        font-size     : 0;
        position      : absolute;
        top           : 80px;
        background    : $color-body-secondary;
        z-index       : 10;
        width         : $full;
        left          : 0;
        padding-bottom: 1rem;
        display       : none;
        @include respond-to(xlarge) {
            position      : relative;
            display       : block;
            width         : auto;
            top           : inherit;
            padding-bottom: 0;
        }

        li {
            font-size: 1rem;
            @include respond-to(xlarge) {
                display       : inline-block;
                vertical-align: top;
                margin        : 0;
            }

            a {
                &.active {
                    color: $color-body;
                }
            }
        }
    }

    .nav-toggle {
        @include animate;
        float    : right;
        font-size: 2.5rem;
        padding  : 1.45rem 0;
        display  : block;
        @include respond-to(xlarge) {
            display: none;
        }

        &:hover {
            cursor: pointer;
            color : rgba($color-highlight, 1);
        }
    }
}
