// =============================================================================
// Home
// =============================================================================

.home-hero-content {
    width   : $full;
    position: relative;
    overflow: hidden;

    h1 {
        @extend %absolute-center;
        width         : 100%;
        vertical-align: middle;
        z-index       : 2;
        color         : $color-background;
        text-shadow   : 0 0 10px rgba($color-body,1);

        .intro {
            width         : 80%;
            font-size     : 80%;
            @include respond-to(large) {
                width    : 40%;
                font-size: $full;
            }
            display       : block;
            margin        : 0 auto;
            text-align    : center;
            letter-spacing: 1px;
        }
    }
}

.home-carousel {
    width     : $full;
    min-height: 300px;
    @include respond-to(large) {
        min-height: none;
    }

    .home-cell {
        width    : auto;
        max-width: none;
        height   : 300px;
        @include respond-to(large) {
            max-width: $full;
            height   : auto;
        }
    }

    .flickity-page-dots {
        bottom: 20px;

        .dot {
            background: $color-body-secondary;
            opacity   : 1;

            &.is-selected {
                background: $color-highlight;
            }
        }
    }
}

.grid-parent {
    &.home {
        .grid-panel {
            &.about {
                padding   : $global-padding;
                background: darken($color-background, 8%);
                height    : 320px;

                h2 {
                    color: $color-body;
                }
            }

            &.get-in-touch {
                padding   : $global-padding;
                color     : $color-body;
                background: darken($color-background, 6%);
                height    : 320px;

                h2 {
                    color: $color-body;
                }
            }

            &.careers {
                padding   : $global-padding;
                background: darken($color-background, 4%);
                height    : 320px;

                h2 {
                    color: $color-body;
                }
            }

            &.latest-news-home {
                padding   : $global-padding;
                background: darken($color-background, 2%);
                display   : block;
                height    : 320px;
                @include respond-to(xlarge) {
                    display: none;
                }

                h2 {
                    color: $color-body;
                }
            }
        }
    }
}

.grid-panel {
    &.testimonials {
        background: $color-secondary;
        color     : $color-body-secondary;
        padding   : $global-padding;
        position  : relative;

        .testimonials-carousel-nav {
            font-size: 3rem;

            &:hover {
                cursor: pointer;
                color : $color-body-secondary;
            }

            &.testimonials-carousel-next {
                left: $global-padding*3;
            }
        }
    }

    &.testimonials-carousel-panel {
        width: $full;
    }

    .testimonials-carousel {
        height: $full;

        .flickity-page-dots {
            bottom: $global-padding;

            .dot {
                background: $color-body-secondary;
                opacity   : 1;

                &.is-selected {
                    background: $color-highlight;
                }
            }
        }

        .testimonials-cell {
            padding: $global-padding $global-padding $global-padding*2.5;
            height : $full;
            width  : $full;

            &:nth-child(1n) {
                background: $color-highlight;
                color     : $color-body-secondary;
            }

            &:nth-child(2n) {
                background: $color-primary;
                color     : $color-body-secondary;
            }

            // &:nth-child(3n + 1) {
            //     background: lighten($color-highlight, 10%);
            //     color     : $color-body-secondary;
            // }

            .cell-content {
                width    : 80%;
                font-size: 0;
                @extend %absolute-center;

                h4 {
                    font-size: 1.125rem;
                }

                .avatar {
                    width         : 200px;
                    height        : 200px;
                    border-radius : 400px;
                    overflow      : hidden;
                    vertical-align: middle;
                    display       : none;
                    @include respond-to(xlarge) {
                        display: inline-block;
                    }
                }

                .quote {
                    width         : $full;
                    margin-right  : $global-padding;
                    display       : inline-block;
                    vertical-align: middle;
                    margin-bottom : 1rem;
                    font-family   : $body-font-thin;
                    letter-spacing: 1px;
                    font-size     : 1.1rem;
                    line-height   : 1.4rem;
                    @include respond-to(large) {
                        font-size  : 1.8rem;
                        line-height: 2.2rem;
                    }
                    @include respond-to(xlarge) {
                        width: calc(100% - (200px + 2rem));
                    }

                    &:before {
                        display    : block;
                        font-size  : 5rem;
                        content    : open-quote;
                        font-family: $body-font;
                    }

                    &:after {
                        content: no-close-quote;
                    }

                    p {
                        margin-bottom: 0;
                        font-family  : $body-font-thin;
                    }
                }
            }
        }
    }
}

.services {
    .grid-panel {
        color: $color-body-secondary;

        .content-block {
            @include respond-to(xlarge) {
                min-height: 250px;
            }

            h3 {
                &.dash {
                    &:before {
                        background: $color-highlight;
                    }
                }
            }

            a {
                &:active,
                &:link,
                &:visited {
                    color: $color-body-secondary;
                }

                &:hover {
                    color: $color-highlight;
                }
            }
        }

        h3 {
            //color: $color-highlight;
        }

        &:first-child {
            .content-block {
                background: $color-primary;

            }
        }

        &:nth-child(2) {
            .content-block {
                background: lighten($color-primary, 2%);
            }
        }

        &:nth-child(3) {
            .content-block {
                background: lighten($color-primary, 4%);
            }
        }

        &:nth-child(4) {
            .content-block {
                background: lighten($color-primary, 6%);
            }
        }

        p:not( &:last-child),
        p:not(&:only-child) {
            margin-bottom: 1rem;
        }
    }
}
