// =============================================================================
// Staff Portal
// =============================================================================
.staff-portal {
    h2 {
        border-bottom : 1px solid darken($color-background, 25%);
        margin-bottom : 1.5rem;
        padding-bottom: 0.15rem;
    }

    ul {
        margin-bottom: $global-padding;
    }
}

.staff-portal-announcements {
    margin-bottom: $global-padding*2 !important;

    .flickity-viewport {
        transition: height 0.2s;
    }

    .flickity-page-dots {
        li {
            padding: 0;
            margin : 0 5px;
        }
    }
}

.staff-portal-announcements,
.staff-portal-documents,
.staff-portal-videos {
    @include clearlist;

    li {
        background   : $color-background;
        padding      : $global-padding;
        width        : $full;
        margin-bottom: $global-padding/2;
        margin-right : 0;

        h3 {
            margin-bottom: 0.15rem;
        }

        .date {
            padding-bottom: $global-padding/2;
            border-bottom : 1px solid darken($color-background, 15%);
        }
    }
}

.staff-portal-documents,
.staff-portal-videos {
    @include clearlist;
    width    : $full;
    display  : flex;
    flex-flow: row wrap;

    li {
        flex: 1 auto 0;
    }
}

.staff-portal-announcements,
.staff-portal-videos {
    li {
        @include respond-to(xlarge) {
            width: calc(50% - 0.5rem);

            &:not(:nth-child(2n)) {
                margin-right: $global-padding/2;
            }
        }
    }
}

&.staff-portal-documents {
    li {
        @include respond-to(xlarge) {
            width: calc(33.3333333% - .67rem);

            &:not(:nth-child(3n)) {
                margin-right: $global-padding/2;
            }
        }
    }
}
