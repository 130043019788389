// =============================================================================
// Base
// =============================================================================

* {
    box-sizing        : border-box!important;
    -moz-box-sizing   : border-box!important;
    -webkit-appearance: none;
    font-weight       : 400;
}

body,
html {
    height                 : 100%;
    margin                 : 0;
    -webkit-font-smoothing : antialiased;
    -moz-osx-font-smoothing: grayscale;
    color                  : $color-body;
}

html {
    color      : $color-primary;
    font-size  : $base-font-size;
    line-height: 1em;
    height     : 100%;
    margin     : 0;
}

body {
    background: $color-background;

    .whole-site-wrapper {
        max-width : $max-width;
        margin    : 0 auto;
        background: $color-body-secondary;

        .site-content-wrapper {
            position  : relative;
            z-index   : 1;
            background: $color-body-secondary;
        }
    }
}

::-moz-selection {
    background: $color-highlight;
    color     : $color-primary;
}

::selection {
    background: $color-highlight;
    color     : $color-primary;
}

hr {
    display   : block;
    margin    : 1em 0;
    padding   : 0;
    height    : 1px;
    border    : 0;
    border-top: 1px solid #ccc;
}

audio,
canvas,
iframe,
img,
svg,
video {
    max-width     : 100%;
    vertical-align: middle;
}

fieldset {
    margin : 0;
    padding: 0;
    border : 0;
}

textarea {
    resize: vertical;
}

.l {
    float: left;
}

.r {
    float: right!important;
}

.ra {
    text-align: right;
}

.ca {
    text-align: center;
}

.embed-container {
    position      : relative;
    padding-bottom: 56.25%;
    height        : 0;
    overflow      : hidden;
    max-width     : 100%;

    embed,
    iframe,
    object {
        position: absolute;
        top     : 0;
        left    : 0;
        width   : 100%!important;
        height  : 100%!important;
    }
}

.hidden {
    display: none;
}
