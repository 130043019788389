// =============================================================================
// Global
// =============================================================================

.lead-title-header {
    width     : $full;
    background: $color-highlight;
    color     : $color-background;

    h1 {
        font-weight  : 500;
        margin-bottom: 0;
        max-width    : 80%;
        margin       : 0 auto;
        //padding      : $global-padding*2.5 0 $global-padding*1.5;
        padding      : $global-padding*1.5 0;
    }
}

.lead-hero-content {
    @include bg-size;
    background-position: center;
    background-repeat  : no-repeat;
    width              : $full;
    min-height         : 300px;
    position           : relative;
    overflow           : hidden;
    display            : table;
    @include respond-to(large) {
        min-height: 550px;
    }

    .intro {
        width         : 80%;
        font-size     : 80%;
        @include respond-to(large) {
            width    : 40%;
            font-size: $full;
        }
        display       : block;
        margin        : 0 auto;
        text-align    : center;
        letter-spacing: 1px;
    }

    &.sub-title {
        .section-title {
            bottom    : 0;
            left      : 0;
            width     : $full;
            padding   : $global-padding 5%;
            margin    : 300px auto 0;
            background: $color-primary;
            color     : $color-background;
            z-index   : 1;
            @include respond-to(large) {
                position : absolute;
                padding  : $global-padding*1.5 5%;
                max-width: 90%;
                left     : 5%;
            }

            h1 {
                font-weight  : 500;
                text-shadow  : none;
                margin-bottom: 0;

                &.sub {
                    color: darken($color-highlight, 20%);
                }

                span {
                    color: $color-background;
                }
            }
        }
    }

    &.min {
        height    : 320px;
        min-height: inherit;
    }

    &:not(.min) {
        h1 {
            display       : table-cell;
            vertical-align: middle;
            position      : relative;
            z-index       : 2;
            color         : $color-background;
            text-shadow   : 0 0 10px rgba($color-body,1);

            .intro {
                width         : 80%;
                font-size     : 80%;
                @include respond-to(large) {
                    width    : 40%;
                    font-size: $full;
                }
                display       : block;
                margin        : 0 auto;
                text-align    : center;
                letter-spacing: 1px;
            }
        }
    }

    .overlay {
        background: rgba($color-highlight, 0.5);
        position  : absolute;
        top       : 0;
        left      : 0;
        width     : $full;
        height    : $full;
        z-index   : 1;
    }

}

.section-title-header {
    @include clearfix;
    padding      : $global-padding;
    width        : $full;
    position     : relative;
    border-top   : 1px solid darken($color-background, 4%);
    border-bottom: 1px solid darken($color-background, 4%);

    h2 {
        font-weight   : 500;
        margin-bottom : 0;
        position      : relative;
        display       : inline-block;
        vertical-align: middle;
        line-height   : 1rem;
        margin-left   : 0;
        margin-right  : 1rem;
    }

    .dash {
        width         : 32px;
        height        : 4px;
        background    : $green;
        //display       : inline-block;
        display       : none;
        vertical-align: middle;

        &:nth-last-child(2) {
            background: $cyan-blue;
        }

        &:last-child {
            background: $yellow;
        }
    }

    .carousel-nav-wrap {
        position  : absolute;
        right     : 1rem;
        height    : 100%;
        top       : 50%;
        margin-top: -24px;

        .carousel-nav {
            font-size: 3rem;
            color    : $color-primary;

            &:active,
            &:link,
            &:visited {
                color : $color-primary;
                border: 0;
            }

            &:hover {
                color : $color-highlight;
                cursor: pointer;
            }
        }
    }
}

.get-in-touch-banner {
    @include clearfix;
    padding   : $global-padding;
    width     : $full;
    position  : relative;
    background: lighten($color-highlight, 25%);

    display   : none;
    @include respond-to(xlarge) {
        display: block;
    }

    span {
        float: left;

        h1,
        h2 {
            margin: 0;
        }

        a {
            border-bottom: 0;

            &:active,
            &:link,
            &:visited {
                color: $color-body;
            }

            &:hover {
                color: $color-highlight;
            }
        }
    }

    .btn {
        position : absolute;
        right    : 0;
        height   : 100%;
        top      : 0;
        padding  : 3.2rem;
        font-size: 1.4rem;
        width    : 33.3333%;
        border   : 0;

        &:active,
        &:link,
        &:visited {
            background: $color-highlight;
        }

        &:hover {
            background: lighten($color-highlight, 10%);
        }
    }
}

.grid-parent {
    @include clearfix;

    .grid-panel {
        height: auto;
        float : left;
        width : $full;
        @include respond-to(medium) {
            height: 420px;
            width : 50%;

            &.x2_3 {
                width: $full;
            }

        }
        @include respond-to(xlarge) {
            &.x1_3 {
                width: 33.3333333%;
            }

            &.x2_3 {
                width: 66.6666667%;
            }
        }
        @include respond-to(xxlarge) {
            &.x1_4 {
                width: 25%;
            }
        }

        &.image {
            @include bg-size;
            position: relative;
            overflow: hidden;

            p {
                margin-bottom: 1em;
                @include respond-to(xxlarge) {
                    margin-bottom: 0;
                }
            }

            &:hover {
                .content-block {
                    @include respond-to(xxlarge) {
                        padding-bottom: $global-padding * 3;
                    }

                    a {
                        opacity    : 1;
                        padding-top: $global-padding / 1;
                    }
                }
            }

            .content-block {
                @extend %animate;
                width        : $full;
                margin-bottom: 0;
                position     : relative;
                bottom       : 0;
                z-index      : 1;
                left         : 0;
                margin-top   : 240px;
                padding      : $global-padding;
                min-height   : 200px;
                @include respond-to(medium) {
                    position: absolute;
                }

                a {
                    font-family: $header-font;
                    font-weight: 500;
                    @include respond-to(xxlarge) {
                        position: absolute;
                        opacity : 0;
                    }
                }
            }

            img {
                width: $full;

                &.center {
                    @extend %absolute-center;
                }

                &.top {
                    position: absolute;
                    top     : 0;
                    left    : 0;
                    z-index : 0;
                }

                &.center-full {
                    @extend %absolute-center;
                    width    : auto;
                    max-width: none;
                    height   : 100%;
                }
            }
        }

        &.text {
            position: relative;

            p {
                margin-bottom: 1em;
                @include respond-to(xxlarge) {
                    margin-bottom: 0;
                }
            }

            &:hover {
                .content-block {
                    @include respond-to(xxlarge) {
                        padding-bottom: $global-padding * 3;
                    }

                    a {
                        opacity    : 1;
                        padding-top: $global-padding / 1;

                        &:hover {
                            &:before {
                                opacity: 1;
                                bottom : 1.5rem;
                            }
                        }
                    }
                }
            }

            .content-block {
                @extend %animate;
                position: absolute;
                width   : $full;
                bottom  : 0;
                left    : 0;
                padding : $global-padding;

                a {
                    font-family: $header-font;
                    font-weight: 500;
                    @include respond-to(xxlarge) {
                        position: absolute;
                        opacity : 0;
                    }
                }
            }
        }

        &.staff {
            height    : auto;
            background: $color-primary;
            color     : $color-body-secondary;

            .img-wrap {
                width   : $full;
                height  : 180px;
                overflow: hidden;
                position: relative;

                img {
                    width     : $full;
                    min-height: $full;
                    @extend %absolute-center;
                }
            }

            .content-block {
                padding     : $global-padding;
                border-right: 1px solid darken($color-body, 10%);
                min-height  : 255px;

                // position: absolute;
                // height: 100%;
                // width: 100%;

                h2 {
                    &.dash {
                        &:before {
                            background: $color-highlight;
                        }
                    }
                }

                a {
                    &:active,
                    &:link,
                    &:visited {
                        color: $color-body-secondary;
                    }

                    &:hover {
                        color: $color-body;
                    }
                }

            }
        }

        &.services {
            .content-block {
                background  : $color-body;
                color       : $color-body-secondary;
                border-right: 1px solid darken($color-body, 10%);
            }
        }
    }
}

.row {
    width     : $full;
    background: darken($color-background, 4%);

    &.light {
        background   : white;
        border-bottom: 1px solid darken($color-background, 4%);
    }

    .panel {
        @include clearfix;
        width    : $full;
        max-width: 90%;
        margin   : 0 auto;
        padding  : $global-padding 0;
        @include respond-to(large) {
            max-width: 80%;
            padding  : $global-padding*2 0;
        }

        &.no-whitespace {
            padding: 0;
        }

        .col {
            float        : left;
            width        : $full;
            margin-bottom: $global-padding;
            @include respond-to(large) {
                margin-bottom: 0;

                &.x1_3 {
                    width: 33.3333333%;
                }

                &.x2_3 {
                    width        : 66.6666667%;
                    padding-right: $global-padding*2;
                }
            }

            &.no-float {
                float: none;
            }

            &.editorial {
                font-size  : 1.1rem;
                line-height: 1.6rem;

                p {
                    &:not(:last-child) {
                        margin-bottom: $global-padding;
                    }

                    &:first-child {
                        font-size  : 1.5rem;
                        line-height: 2.1rem;
                    }

                }

                ul {
                    margin-bottom: $global-padding;
                    margin-left  : $global-padding;
                    list-style   : disc;
                }

                .embed-container,
                img {
                    margin-bottom: 2rem !important;
                    border       : 6px solid $color-background;
                }

                :last-child {
                    margin-bottom: 0;
                }
            }

            &.contactus {
                h3 {
                    margin-bottom: 0.5rem;
                }

                h3,
                h4 {
                    a {
                        &:active,
                        &:link,
                        &:visited {
                            color : $color-body;
                            border: 0;
                        }

                        &:hover {
                            color: $color-highlight;
                        }
                    }
                }
            }
        }

    }
}

.accreditations-carousel {
    border-top: 1px solid darken($color-background, 4%);
    height    : 200px;

    .accreditations-cell {
        height    : 200px;
        display   : table;
        padding   : $global-padding*2 $global-padding;
        text-align: center;
        width     : 50%;
        @include respond-to(medium) {
            width: 33.333%;
        }
        @include respond-to(large) {
            width: 20%;
        }

        span {
            display       : table-cell;
            vertical-align: middle;

            img {
                max-width: 80%;
            }
        }
    }
}

.feature-thumbnail {
    display: none!important;
}
