// ==========================================================================
// Mixins
// ==========================================================================

// Legacy support for inline-block in IE7 (maybe IE6)
@mixin inline-block {
	display: inline-block;
	vertical-align: middle;
	zoom: 1;
	*display: inline;
	*vertical-align: auto;
}

@mixin clearlist {
	margin: 0;
	padding: 0;
	list-style: none;
	list-style-type: none;
}

@mixin bg-size {
	background-size: cover;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-ms-background-size: cover;
	-o-background-size: cover;
}

@mixin animate {
	-webkit-transition:all .2s ease;
	-moz-transition:all .2s ease;
	-o-transition:all .2s ease;
	transition:all .2s ease;
}

@mixin respond-to($media) {
	@if $media == small {
		@media only screen and (min-width: $small) { @content; }
	}
	@else if $media == medium {
		@media only screen and (min-width: $medium) { @content; }
	}
	@else if $media == large {
		@media only screen and (min-width: $large) { @content; }
	}
	@else if $media == xlarge {
		@media only screen and (min-width: $xlarge) { @content; }
	}
	@else if $media == xxlarge {
		@media only screen and (min-width: $xxlarge) { @content; }
	}
}

// Font improvement
@mixin font-smoothing($value: on) {
    @if $value == on {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}

// Loops to enumerate the classes
// Yep, this saves us tons of typing (if this were CSS)
@mixin build-classes($class-slug, $property, $value, $count) {
    @for $i from 1 through $count {
      .#{$class-slug}-x#{$i} { #{$property}: $value * $i; }
    }
}

// @mixin build-column-classes($class-slug) {
//     @for $i from 1 through $grid-columns {
//       .#{$class-slug}-#{$i} {
// 		  width: (((100% / $grid-columns) - ($grid-margin * 2)) * $i);
// 	  }
//     }
// }
